@media only screen and (max-width: 640px) {
    div.header-container {
        padding: 0.5rem 1rem;
        height: inherit;
    }
    header div.picture {display: none;}
    header div.profile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
    header div.profile div.name {
        font-size: 2.4rem;
        padding: 0;
        line-height: 4.8rem;
        display: flex;
        align-items: center;
        align-self: center;
        gap: 6px;
    }
    header div.profile div.name img {
        display: inline;
        height: 3.5rem;
        border-radius: 50%;
        margin-right: 0.8rem;
    }
    header div.profile div.headline {display: none;}
    header div.profile nav {
        width: auto;
        line-height: 4.8rem;
        max-height: 4.8rem;
        padding: 0;
        margin: inherit;
    }
    header div.profile nav a {
        font-size: 2.6rem;
    }
}

